<template>
  <v-container fluid class="pa-0" data-v-step="tvmsg_0">
    <v-list three-line subheader>
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-subheader>{{ $t("tvmessages.header") }}</v-subheader>
          <template v-for="(item, index) in items">
            <v-list-item
              :key="item.id"
              @click="selectedItemIndex = index"
              data-v-step="tvmsg_1"
            >
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'font-weight-bold': !item.viewed }"
                  class="text-overline"
                  v-text="getLocaleDate(item)"
                />
                <v-list-item-subtitle
                  :class="{ 'font-weight-bold': !item.viewed }"
                  v-html="item.text"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon medium>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < items.length - 1"
              :key="item.id + '-divider'"
            ></v-divider>
          </template>
        </v-window-item>

        <v-window-item :value="2">
          <v-card
            flat
            color="transparent"
            v-if="
              selectedItem && statusMark === 'success' && status === 'success'
            "
          >
            <v-toolbar dense flat color="transparent">
              <v-btn icon @click="selectedItemIndex = -1">
                <v-icon medium>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon @click="deleteMessage({ id: selectedItem.id })"
                  >mdi-delete</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon
                  @click="
                    markAsUnread({ messageId: selectedItem.message_id });
                    selectedItemIndex = -1;
                  "
                  >mdi-email</v-icon
                >
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-title>{{ selectedItem.date }}</v-card-title>
            <v-card-text
              style="white-space: pre-wrap"
              v-html="selectedItem.text"
            ></v-card-text>
          </v-card>
        </v-window-item>
      </v-window>
    </v-list>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "TvMessage",
  data() {
    return {
      step: 1,
      selectedItemIndex: -1,
    };
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapState({
      items: ({ tvmessage }) => tvmessage.items,
      status: ({ tvmessage }) => tvmessage.status,
      statusMark: ({ tvmessage }) => tvmessage.statusMark,
      statusDelete: ({ tvmessage }) => tvmessage.statusDelete,
      newTvMessage: ({ tvmessage }) => tvmessage.newTvMessage,
      room: ({ session }) => session.authResult.room,
    }),
    selectedItem() {
      if (this.selectedItemIndex > -1) {
        return this.items[this.selectedItemIndex];
      }
    },
  },
  methods: {
    ...mapActions("tvmessage", [
      "getTvMessages",
      "markAsRead",
      "markAsUnread",
      "deleteMessage",
    ]),
    initData() {
      this.getTvMessages();
    },
    getLocaleDate({ date }) {
      let ret = date;
      try {
        ret = new DateTime(date).toJSDate().toLocaleString();
      } catch (igonre) {}
      return ret;
    },
  },
  watch: {
    selectedItemIndex(val) {
      if (val > -1) {
        this.step = 2;
        this.markAsRead({ messageId: this.selectedItem.message_id });
      } else {
        this.step = 1;
      }
    },
    statusDelete(val) {
      if (val !== "fetching") {
        this.selectedItemIndex = -1;
      }
    },
  },
};
</script>
